// Here you can add other styles
@import '../app/alertNotifications/utils/alertNotifications.scss';

// user email styles
.app-header .navbar-nav .nav-item a span {
  padding-right: 1em;

}

// brand logo style consistency fix
body.sidebar-minimized a.navbar-brand {
  width: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.not-hovered {
  pointer-events: none;
}

.modal-dialog {
  top: 50%;
  transform: translateY(-50%)!important; // hello bootstrap
}

.s-alert-box {
  display: none!important;
}

.display-none {
  display: none;
}

.bg-danger {
  color: red;
  background-color: transparent !important;
}

td {
  outline-style:none;
}

.react-bs-table table td, .react-bs-table table th {
  vertical-align: middle;
}

.public-DraftEditor-content {
  min-height: 140px;
  padding: 10px;
  border-color: #e4e7ea;
  border-radius: 0.25rem;
}

.overflow-reset {
  overflow: initial !important;
}

.inline-form-field-wrapper {
  display: inline-flex;
  text-align: center;
  align-items: center;
}

.search-label {
  width: 100%;
}

.terms-and-conditions-content {
  .public-DraftEditor-content {
    max-height: 300px;
  }
}