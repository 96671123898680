.active-color {
  color: #7C9D00;
  font-weight: 700;
}

.inactive-color {
  color:rgba(108, 127, 141, 1);
}

.color-box {
  color: transparent;
  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 12px;
  }
}

.green-box {
  @extend .color-box;
  &:before { background: #A3BF00; }
}

.orange-box {
  @extend .color-box;
  &:before { background: #F4BC59; }
}

.red-box {
  @extend .color-box;
  &:before { background: #E9735D; }
}

.blue-box {
  @extend .color-box;
  &:before { background: #B5E0F4; }
}